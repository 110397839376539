#kt_header_search {
    .header_search_dropdown {
        // transform: translateY(13px) !important;

        .header_search_form {
            border-bottom: 1px solid rgba(92, 92, 92, 0.5);

            .btn_header_kind {
                padding: 5px 8px;
                background: rgba(0, 158, 247, 0.25);
                border-radius: 6px;

                span {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 15px;
                    color: #000000;
                }

                i {
                    font-size: 13px;
                    line-height: 15px;
                    color: #000000;
                }
            }
        }

        .filter_wrapper {
            padding-top: 22.5px;
            padding-left: 30px;
            padding-right: 30px;

            .filter_label {

                span {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 18px;
                    color: rgba(0, 0, 0, 0.47);
                }
            }

            .kind_wrapper {
                margin-top: 25px;

                .kind_label {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 15px;
                    color: rgba(117, 117, 117, 0.47);
                }

                .button_wrapper {
                    margin-top: 7px;

                    .btn_kind {
                        padding: 8px 10px;
                        background: rgba(0, 158, 247, 0.25);
                        border-radius: 6px;

                        span {
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 10px;
                            line-height: 15px;
                            color: #343434;
                        }
                    }
                }
            }

            .narrow_wrapper {
                .narrow_item {
                    .btn_narrow {
                        padding: 4px 7px;
                        background: rgba(0, 158, 247, 0.25);
                        border-radius: 4px;
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 8px;
                        line-height: 12px;
                        color: #000000;
                    }

                    span {
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 10px;
                        line-height: 15px;
                        color: rgba(117, 117, 117, 0.47);
                    }
                }
            }
        }

        .suggestions_wrapper {
            margin-top: 32px;
            padding: 0px 30px;

            .suggestions_label {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 10px;
                line-height: 15px;
                color: rgba(117, 117, 117, 0.47);
            }

            ul.suggestions_item_wrapper {
                margin-top: 16px;
                list-style: none;
                padding-left: 0;

                li.suggestions_item {
                    button {
                        span {
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 10px;
                            line-height: 15px;
                            color: #000000;
                        }
                    }
                }
            }
        }
    }

    .search_text_wrapper {
        padding: 14px 16px;
        border-radius: 12px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 160%;
        border: 1px solid #E2E8F0;

        span {
            color: #64748B;
        }

        span:last-child {
            display: inline-block;
            width: 200px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &.w_263px {
            width: 263px !important;
        }

        &.btn.btn-icon {
            color: rgba(0, 0, 0, 0.47)
        }
    }

    /* Styles for mobile devices */
@media (max-width: 767px) {
    /* Hide the search box wrapper */
    .search_text_wrapper {
      display: none;
    }

    /* Show only the magnifying glass icon */
    .search_text_wrapper.mobile-icon-only {
      display: block;
      padding: 0;
      background: none;
      border: none;
    }

    .search_text_wrapper.mobile-icon-only span {
      display: none;
    }
  }

    .search_result_list {
        list-style: none;
        padding-left: 0;

        .employee_item {
            .employee_link {
                color: #A1A5B7;

                &:hover {
                    color: #181C32;
                }
            }

            &:hover {
                background-color: #F5F8FA;
            }
        }

        .connection_item {
            .connection_link {
                color: #A1A5B7;

                &:hover {
                    color: #181C32;
                }
            }

            &:hover {
                background-color: #F5F8FA;
            }
        }
    }
}
