//
// Buttons
//

// Import Dependencies
@import "buttons/base";
@import "buttons/theme";
@import "./variables";

.btn:disabled {
	cursor: not-allowed;
}

.btn-check {
	&:checked {
		+ .btn-outline-secondary {
			color: white;
			background-color: $gray-700;
			border-color: $gray-700;
		}
	}
}

.btn-outline-secondary {
	border-color: $gray-600;
	color: $gray-700;
	background-color: white;
}

// fix for BS which is setting top right/bottom left border radius to square in vertical arrangements
.btn-group-vertical {
	> input.btn-check:first-child + label.btn {
		border-top-right-radius: $btn-border-radius;
		// fix BS, which has the top item in a vertical button group not have the same -1px margin-left
		margin-left: calc(1px * -1);
	}

	> input.btn-check + label.btn:last-child {
		border-bottom-left-radius: $btn-border-radius;
	}
}
