.auth_container {
    .btn_show_password {
        top: 13px;
        right: -33px;
    }
}

.form_control_wrapper {
    >i {
        top: 19px;
        left: 15px;
    }
    .form-control {
        padding: 16px 45px;
        color: #94A3B8;

        &.is-invalid.no-error-icon {
            background-image: none;
        }
    }
}
