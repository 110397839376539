.search_for_advanced_wrapper {
    .search_text_wrapper {
        padding: 0;
        border-radius: 12px;
        // border: 1px solid #E2E8F0;

        span {
            color: #64748B;
            left: 14px;
            top: 14px;
        }

        input {
            border: 1px solid #E2E8F0;
            border-radius: 12px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 160%;
            color: #64748B;
        }

        // span:last-child {
        //     display: inline-block;
        //     width: 200px;
        //     white-space: nowrap;
        //     overflow: hidden;
        //     text-overflow: ellipsis;
        // }

        &.btn.btn-icon {
            color: rgba(0, 0, 0, 0.47)
        }
    }
}