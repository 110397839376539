.invite_settings_container {
    .react-multi-email {
        width: 100%;
        min-height: 117px;
        border: 2px solid #009EF7;

        > div {
            background: #E1F4FF;
            border-radius: 8px;

            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: #009EF7;

            span:first-child {
                text-decoration-line: underline;
            }
        }
    }
    .invite_footer {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        > span {
            font-size: 14px;
            line-height: 21px;
            color: rgba(24, 28, 50, 0.5);

            b {
                background: #E1F4FF;
                border-radius: 8px;
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                color: #000;
                padding: 6px 20px;
            }
        }
    }
}