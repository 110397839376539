.notification_wrapper {
    position: relative;
    margin-bottom: 16px;
    padding: 20px;
    border-radius: 12px;
    cursor: pointer;

    .notification_icon {
        border-radius: 50%;
        margin-right: 16px;
    }

    .notification_content {
        .notification_creator_name {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 160%;
            letter-spacing: 0.2px;
            color: #0F172A;
        }

        .notification_message {
            margin-top: 2px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 160%;
            color: #94A3B8;
            word-break: break-all;
            // width: 160px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }
    }

    .notification_action {
        min-width: 35px;
        padding-bottom: 20px;

        .notification_date {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            line-height: 160%;
            color: #94A3B8;
        }

        .notification_mark {
            margin-top: 16px;
            display: inline-block;
            width: 10px;
            height: 10px;
            border-radius: 50%;
        }

    }
}
