#kt_aside.aside.aside-dark {
    background-color: #fff;

    .aside-logo {
        background-color: #fff;
    }

    .menu {

        .menu-item {
            .menu-link {

                &.active {
                    background-color: #ededed;

                    .menu-title {
                        color: #000;
                    }
                }
            }

            // &:hover {
            //     background-color: #ededed;

            //     .menu-title {
            //         color: #000;
            //     }
            // }

            &.show {

                > .menu-link {
                    background-color: #ededed;

                    .menu-title {
                        color: #000;
                    }
                }
            }

            &.here {
                > .menu-link {
                    background-color: #ededed;

                    .menu-title {
                        color: #000;
                    }
                }
            }
        }

        .menu-item.hover:not(.here) > .menu-link:not(.disabled):not(.active):not(.here), .menu-item:not(.here) .menu-link:hover:not(.disabled):not(.active):not(.here) {
            background-color: #ededed;

            .menu-title {
                color: #000;
            }
        }
    }
}


// @media (min-width: 992px) {
//     .header-fixed.toolbar-fixed .page .wrapper {
//         padding-top: 65px;
//     }

//     .header-fixed.toolbar-fixed .page .wrapper.headerwrapper_hidden {
//         padding-top: 0px;
//     }
// }

// @media (max-width: 991.98px) {
//     .header-tablet-and-mobile-fixed.toolbar-tablet-and-mobile-fixed .page .wrapper {
//         padding-top: 55px;
//     }

//     .header-tablet-and-mobile-fixed.toolbar-tablet-and-mobile-fixed .page .wrapper.headerwrapper_hidden {
//         padding-top: 0;
//     }
// }