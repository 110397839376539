//
// Theme style
//

// Mode
$mode: default;

// Initialize
@import "init";

// Components
@import "./core/components/components";
@import "components/components";

// Layout
@import "layout/layout";
@import "./core/layout/docs/layout";

.h1_font {
    font-family: Poppins;
    font-weight: bold;
    font-size: clamp(36px, 4vw, 72px);
}

.h1_brand_font {
    font-family: Poppins;
    font-weight: bold;
    font-size: 36px;
}

.h3_font {
    font-family: Poppins;
    font-weight: 600;
    font-size: clamp(19px, 2vw, 22px);
}

.h3_brand_font {
    font-family: Poppins;
    font-weight: 600;
    font-size: 22px;
}

.h2_font {
    font-family: Roboto;
    font-weight: 600;
    font-size: clamp(26px, 4vw, 48px);
}

.h2_brand_font {
    font-family: Roboto;
    font-weight: 600;
    font-size: 28px;
}

.h4_font {
    font-family: Roboto;
    font-size: clamp(19px, 2vw, 22px);
}

.h4_brand_font {
    font-family: Roboto;
    font-size: 16px;
}

.text-align-right {
    text-align: right !important;
}

.info_tag_font {
    font-family: Poppins;
    font-weight: 600;
    font-size: 12px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.body_text_font {
    font-family: Poppins;
    font-size: clamp(15px, 2vw, 16px);
}

.nav_item_font {
    font-family: Poppins;
    font-weight: 600;
    font-size: 18px;
}

.cta_small_font {
    font-family: Poppins;
    font-weight: 500;
    font-size: 16px;
}

.cta_large_font {
    font-family: Poppins;
    font-weight: 600;
    font-size: 24px;
}

.cta_normal_font {
    font-family: Poppins;
    font-weight: 600;
    font-size: 18px;
}

.no_bold {
    font-weight: normal;
}

.width_100 {
    width: 100%;
}

.poppins_semibold {
    font-family: Poppins;
    font-weight: 600;
}

.poppins_medium {
    font-family: Poppins;
    font-weight: 500;
}

.button-2-container {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.button-2 {
    width: 20px;
    height: 5px;
    border-radius: 15px;
    background: #e0e0e0;
    margin: 6px;
    cursor: pointer;
}

.button-2-container .active {
    background: #999;
}

.card-body .symbol-label .svg-icon {
    margin-right: 0px !important;
}

.card-body .symbol-label svg {
    width: 24px !important;
    height: 24px !important;
}

.menu-link2.active .menu-title {
    color: #009ef7 !important;
}

.create-team-file-block {
    .dropzone_wrapper {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .dropzone_wrapper h5 {
        font-size: 14px;
    }

    .dropzone_wrapper p {
        font-size: 12px;
    }
}

#import_csv_modal {
    .modal-close {
        top: 20px;
        right: 10px;
    }

    .csv-tab {
        background-color: #e0e0e0;
    }

    .nav-link.active .csv-tab-border {
        background-color: #813ad7;
    }
}

#create_connection .modal-close,
#required_action_modal .modal-close,
#search_user_modal .modal-close {
    top: 20px;
    right: 10px;
    z-index: 10;
}

.required-team-name {
    min-width: 100px;
    border-bottom: 1px solid #009ef7;
    margin: 0px 10px;
}

.recent-connect-block,
.risk-block {
    max-height: 368px;
    overflow-y: scroll;

    .card-body {
        overflow-y: scroll;
    }
}

.risk-block {

    // max-height: 430px;
    .card-body {
        overflow-y: scroll;
    }
}

#import_csv_modal .nav-line-tabs .nav-item .nav-link.active {
    border-bottom: 0px !important;
}

#import_csv_modal .nav-line-tabs .nav-item .nav-link {
    margin-left: 0px !important;
}

.disabled-link {
    pointer-events: none;
}

.modal-header .modal-close {
    right: 10px;
}

thead button {
    border: 0;
    border-radius: none;
    font-family: inherit;
    font-weight: 700;
    font-size: inherit;
    padding: 0.5em;
    margin-bottom: 1px;
    background: transparent;
}

thead button.ascending::after {
    content: "👇";
    display: inline-block;
    margin-left: 1em;
}

thead button.descending::after {
    content: "☝️";
    display: inline-block;
    margin-left: 1em;
}

.pointer-event:hover {
    cursor: pointer;
}

@media (min-width: 768px) {
    .w_80 {
        width: 80%;
    }
}

.fade-enter {
    opacity: 0.01;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 3000ms ease-in;
}

.fade-exit {
    opacity: 1;
}

.fade-exit.fade-exit-active {
    opacity: 0;
    transition: opacity 3000ms ease-in;
}

.fade-appear {
    opacity: 0;
}

.fade-appear.fade-appear-active {
    opacity: 1;
    transition: opacity 3000ms ease-in;
}

.btn_remove, .btn_edit {
    cursor: pointer;

    &:hover {
        opacity: .7;
    }
}
