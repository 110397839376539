.topbar_usermenu {
    .usermenu_name {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 160%;
        color: #0F172A;
    }

    .usermenu_role {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 160%;
        color: #64748B;
    }

}

@-o-keyframes fadeIt {
    0%   { fill: red; }
    50%  { fill: rgba(255, 0, 0, 0.2); }
    100% { fill: red }
}
@keyframes fadeIt {
    0%   { fill: red; }
    50%  { fill: rgba(255, 0, 0, 0.2); }
    100% { fill: red; }
}

.notification-trigger {
    .bell {
        display: block;
        fill: #666666;
        transition: ease-out 1s;
    }

    .bell-fill {
        display: none;
        transition: ease-in 1s;
    }
    &.has-unread-maintenance-notifications {
        .bell {
            display: none;
        }

        .bell-fill {
            display: block;
            -o-animation: fadeIt 3s ease-in-out;
            animation: fadeIt 3s ease-in-out;
            animation-iteration-count: infinite;
        }
    }
}

@media only screen and (max-width: 768px) {
    .topbar_usermenu .usermenu_name,
    .topbar_usermenu .usermenu_role {
        display: none;
    }
}
