.team-card-calendar {
    .rbc-row-segment {

        .rbc-event {
            width: 13%;
            height: 60px;
            top: 0;
            position: absolute;
            z-index: -1;
            padding: 0;
            border-radius: 0;
            background-color: #ff4a32;
        }
    }
}



.calendar-container {
    width: 100%;
}

.rbc-date-cell {
    text-align: center;
    margin-top: 20px;
}

.rbc-month-row+.rbc-month-row {
    border-top: 0;
}

.rbc-day-bg+.rbc-day-bg {
    border-left: 0;
}

.rbc-header+.rbc-header {
    border-left: 0;
    padding: 10px 5px;
}

.rbc-header {
    border-left: 0;
    padding: 10px 5px;
    border-bottom: 0;
}

.rbc-time-view .rbc-row {
    min-height: unset;
}

.rbc-time-header.rbc-overflowing {
    border-right: 0;
}

.rbc-time-content {
    border-top: 0;
}

.rbc-off-range-bg {
    background: none;
}

.rbc-month-view {
    border: none;
}

$recognitionColor: green;
$coachingColor: dodgerblue;
$attendanceColor: orange;
$disciplineColor: red;
$currentDayColor: lightgray;
$excusedColor: gray;
$reviewColor: purple;

.calendar-legend {
    .event-label {
        &::before {
            height: 26px;
            width: 26px;
            border-radius: 50%;
            border-width: 2px;
            border-style: solid;
            display: inline-block;
            margin-right: 5px;
            content: ' ';
        }

        &.attendance-event {
            &::before {
                border-color: $attendanceColor;
            }
        }

        &.coaching-event {
            &::before {
                border-color: $coachingColor;
            }
        }

        &.discipline-event {
            &::before {
                border-color: $disciplineColor;
            }
        }

        &.excused-event {
            &::before {
                border-color: $excusedColor;
            }
        }

        &.recognition-event {
            &::before {
                border-color: $recognitionColor;
            }
        }

        &.review-event {
            &::before {
                border-color: $reviewColor;
            }
        }
    }
}

.react-calendar__month-view__days__day--weekend {
    // don't highlight weekends
    color: unset;
}

.react-calendar {
    border: none;
    width: 100%;

    //.react-calendar__month-view__weekdays {
    //    abbr {
    //        margin: unset;
    //    }
    //}

    .react-calendar__tile--active {
        &.react-calendar__tile--overBothEnds {
            background-color: deeppink;
        }

        //calendar__tile--range react-calendar__tile--rangeStart react-calendar__tile--rangeEnd react-calendar__tile--rangeBothEnds
    }

    &.year-calendar {
        .react-calendar__navigation {
            border: none;

            button.react-calendar__navigation__label {
                font-weight: bolder;
                cursor: unset;

                //&:hover:not(.react-calendar__tile--active) {
                //    background-color: transparent;
                //}
            }
        }

        .outside-active-window {
            color: gray;
            cursor: not-allowed;

            abbr {
                cursor: not-allowed;
            }
        }
    }

    .react-calendar__tile {
        line-height: 45px;
        font-size: 16px;

        &.today {
            background-color: $currentDayColor;
        }

        abbr {
            border-radius: 50%;
            padding: 11px;
            width: 20px;
            height: 20px;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: center;

        }

        &.recognition-event {
            abbr {
                border: 2px solid $recognitionColor;
            }
        }

        &.review-event {
            abbr {
                border: 2px solid $reviewColor;
            }
        }

        &.coaching-event {
            abbr {
                border: 2px solid $coachingColor;
            }
        }

        &.attendance-event {
            abbr {
                border: 2px solid $attendanceColor;
            }
        }

        &.excused-event {
            abbr {
                border: 2px solid $excusedColor;
            }
        }

        &.discipline-event {
            abbr {
                border: 2px solid $disciplineColor;
            }
        }

        &.no-events {
            abbr {
                border: none;
            }
        }

        &:disabled {
            background-color: transparent;
        }

        //&:enabled:hover,
        //&:enabled:focus {
        //    background-color: transparent;
        //}
    }

    .react-calendar__month-view__weekdays__weekday {
        font-size: 11px;

        abbr {
            text-decoration: none;
            border-bottom: none;
        }
    }

    .react-calendar__navigation {
        border: 1px solid #a0a096;

        .react-calendar__navigation__label {
            .react-calendar__navigation__label__labelText {
                font-size: 16px;
            }
        }

        .react-calendar__navigation__arrow {
            font-size: 20px;
        }
    }

    .react-calendar__tile--now {
        background: transparent;

        //abbr {
        //    font-weight: bolder;
        //    background: $currentDayColor;
        //    border-radius: 50%;
        //    padding: 11px;
        //    width: 45px;
        //    height: 45px;
        //    margin: auto;
        //    display: flex;
        //    align-items: center;
        //    justify-content: center;
        //}

        //&:enabled:hover,
        //&:enabled:focus {
        //    background-color: transparent;
        //}
    }

    .react-calendar__tile--active {
        color: black;
        background-color: transparent;
        // TODO: color for selected dates
        //background: none;
        //background-color: slategray;

        //abbr {
        //    background: #009EF7;
        //    border-radius: 50%;
        //    padding: 11px;
        //    width: 45px;
        //    height: 45px;
        //    margin: auto;
        //    display: flex;
        //    align-items: center;
        //    justify-content: center;
        //}

        //&:enabled:hover,
        //&:enabled:focus {
        //    background-color: transparent;
        //}
    }
}

.card-body {
    .calendar {
        .month-container {
            .day {
                .day-content {
                    padding: 3px 6px;
                    border-radius: 50%;

                    &.recognition-event {
                        border: 2px solid $recognitionColor;
                    }

                    &.review-event {
                        border: 2px solid $reviewColor;
                    }

                    &.coaching-event {
                        border: 2px solid $coachingColor;

                    }

                    &.attendance-event {
                        border: 2px solid $attendanceColor;
                    }

                    &.excused-event {
                        border: 2px solid $excusedColor;
                    }

                    &.discipline-event {
                        border: 2px solid $disciplineColor;
                    }

                }
            }
        }
    }
}

@media (max-width: 575.98px) {
    .react-calendar {
        width: 100%;

        .react-calendar__tile {
            line-height: 20px;
            font-size: 12px;

            &.recognition-event {
                abbr {
                    border: 2px solid $recognitionColor;
                    border-radius: 50%;
                    padding: 5px 5px;
                    width: 32px;
                    height: 32px;
                }
            }

            &.review-event {
                abbr {
                    border: 2px solid $reviewColor;
                    border-radius: 50%;
                    padding: 5px 5px;
                    width: 32px;
                    height: 32px;
                }
            }

            &.coaching-event {
                abbr {
                    border: 2px solid $coachingColor;
                    border-radius: 50%;
                    padding: 5px 5px;
                    width: 32px;
                    height: 32px;
                }
            }

            &.attendance-event {
                abbr {
                    border: 2px solid $attendanceColor;
                    border-radius: 50%;
                    padding: 5px 5px;
                    width: 32px;
                    height: 32px;
                }
            }

            &.excused-event {
                abbr {
                    border: 2px solid $excusedColor;
                    border-radius: 50%;
                    padding: 5px 5px;
                    width: 32px;
                    height: 32px;
                }
            }

            &.discipline-event {
                abbr {
                    border: 2px solid $disciplineColor;
                    border-radius: 50%;
                    padding: 5px 5px;
                    width: 32px;
                    height: 32px;
                }
            }
        }
    }
}
