@import "src/_library/assets/sass/core/components/_variables.scss";

#attendance-modal {
	.modal-dialog {
		max-width: 802px;
		background: #FFFFFF;
		box-shadow: 0 1px 120px rgba(0, 0, 0, 0.12);
		border-radius: 20px;

		.modal-body {
			padding: 50px;

			.unexcused-rules {
				.btn-check:not(:checked) + .btn-outline-primary {
					span.occurrence-points.positive {
						color: $danger;
					}
				}
				.btn-check:checked + .btn-outline-primary {
					span.occurrence-points {
						color: inherit;
					}

				}
			}
		}
	}
}
