.home_navbar.navbar {
    padding-left: 5%;
    padding-right: 5%;
    // padding-top: 40px;
    // padding-bottom: 40px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;

    .navbar-brand {
        margin-right: 35px;
    }

    .nav-item {
        font-family: Poppins;
        font-weight: 600;
        font-size: 18px;
        padding-left: 35px;
        padding-right: 35px;
    }

    .btn.btn_login {
        font-weight: 600;
        font-family: Poppins;
        font-size: 18px;
        color: #000;
        // color: #F1AF2C;
        // border: 2px solid #F1AF2C !important;
        padding: 10px 25px !important;
        border: 2px solid #000 !important;
        border-radius: 30px;
        margin-right: 16px;
    }

    .btn.btn_book {
        font-weight: 600;
        font-family: Poppins;
        font-size: 18px;
        padding: 10px 25px !important;
        // border: 2px solid #F1AF2C !important;
        // background: #F1AF2C 0% 0% no-repeat padding-box;
        border: 2px solid #000 !important;
        background: #000 0% 0% no-repeat padding-box;
        color: #fff;
        border-radius: 30px;
    }
}

@media(max-width: 992px) {
    .home_navbar.navbar {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;

        .nav-item {
            padding: 0;
        }

        .btn.btn_login, .btn.btn_book {
            padding: 10px 15px !important;
        }
    }

}