#kt_drawer_notification {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;

    .card {
        .card-header {
            .card-title {
                span {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 160%;
                    letter-spacing: 0.2px;
                    color: #0F172A;
                }
            }
        }

        .card-body {
            padding: 16px 24px;


            .buttons_wrapper {
                .btn_all {
                    margin-right: 8px;
                    padding: 5px 12px;
                    margin-bottom: 5px;
                    background: #EFF6FF;
                    border-radius: 23px;
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 160%;
                    letter-spacing: 0.2px;
                    color: #2563EB;

                    &:hover {
                        opacity: 0.8;
                    }
                }

                .btn_unread {
                    padding: 5px 12px;
                    margin-bottom: 5px;
                    background: #EC3232;
                    border-radius: 23px;
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 160%;
                    letter-spacing: 0.2px;
                    color: #FFFFFF;

                    &:hover {
                        opacity: 0.8;
                    }
                }
            }

            .item_title {
                margin-top: 20px;
                margin-bottom: 16px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 700;
                font-size: 12px;
                line-height: 160%;
                letter-spacing: 1px;
                color: #94A3B8;
            }

            .notifications_container {
                .notification_item {
                    list-style: none;
                }
            }

            .sticky-buttons-actions {
                position: sticky;
                top: 0;
                z-index: 10;
            }

            .scrollable-notifications {
                overflow-y: auto;
                max-height: 900px;
            }

        }
    }
}
