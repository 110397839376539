#kt_page_title.page-title {
    h1 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 125%;
        letter-spacing: 0.2px;
        color: #0F172A;
    }
}