.auth_container {
    overflow-y: hidden;
    height: 100vh;

    .auth_content {
        flex-grow: 1;
        overflow-y: auto;
        padding: 20px;
    }

    .auth_left {
        max-width: 49%;
        position: relative;

        img {
            width: 100%;
            height: auto;
        }

        .img_large_logo {
            position: absolute;
            width: 40%;
            left: 30%;
            top: 30%;
        }
    }


    .mobile_logo_container {
        display: none;
        justify-content: center;
        align-items: center;
        height: 50vh;
    }

    .img_mobile_logo {
        max-width: 150px;
    }


    .form_title {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 125%;
        letter-spacing: 0.2px;
        color: #0F172A;
    }

    .form_subtitle {
        margin-top: 12px;
        margin-bottom: 32px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 160%;
        color: #64748B;
    }

    .form_control_wrapper {
        margin-bottom: 16px;

        >i {
            top: 19px;
            left: 15px;
        }

        .form_control {
            width: 100%;
            padding: 16px 45px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 160%;
            color: #94A3B8;
            border: 1px solid #E2E8F0;
            border-radius: 12px;
        }

        .btn_show_password {
            top: 19px;
            right: 11px;
        }
    }

    .form-check {
        .form-check-input.remember_me_check {
            border-radius: 3px;
            width: 24px;
            height: 24px;
            // background-color: #fff;
        }

        .form-check-label {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 160%;
            letter-spacing: 0.2px;
            color: #0F172A;
        }
    }

    .forgot_password_link {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 160%;
        text-align: right;
        color: #2563EB;
    }

    #login-btn.btn.btn-primary.btn-lg {
        background-color: #2563EB;

        &:hover:not(.btn-active) {
            opacity: 0.7;
            background-color: darken(#2563EB, 10%) !important;
        }
    }

    .responsive-logo {
        max-width: 100px;
    }
      
    @media (min-width: 992px) {
        .responsive-logo {
            max-width: 200px;
        }
    }
      
}

@media (max-width: 950.98px) {
  .auth_container {
        .auth_left {
        display: none;
        }

        .mobile_logo_container {
        display: flex;
        background-color: #0553f9;
        }

        .img_large_logo {
        display: block;
        }
    }
}
