#employee_performance_card {
    position: absolute;

    .modal-dialog {
        max-width: 902px;
        min-height: 1040px;

        .subtitle {
            margin-top: 10px;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #D7D7D7;
        }

        .modal-body {
            padding: 0;
        }
    }
}
