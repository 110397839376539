// TODO: copied codes from TeamMemberView.scss
$recognitionColor: green;
$coachingColor: blue;
$attendanceColor: orange;
$disciplineColor: red;
$excusedColor: gray;
$reviewColor: #7239EA;

#instant-performance-report.card_content_wrapper {
	position: relative;
	padding: 64px 71px 40px 71px;
	width: 902px;
	background: #ffffff;
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 21px;
	color: #000000;
	counter-reset: page;

	@page {
		size: 8.5in 11in;
		margin-top: 1in;
	}

	@media print {
		margin-top: 1in;
	}

	.connection-log-wrapper {

		@media print {
			break-after: page;
		}
	}

	.rolling-calendar-wrapper {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		.calendar-legend {
			justify-content: space-between;

			.event-label {
				&::before {
					height: 26px;
					width: 26px;
					border-radius: 50%;
					border-width: 2px;
					border-style: solid;
					display: inline-block;
					margin-right: 5px;
					content: ' ';
				}

				&.attendance-event {
					&::before {
						border-color: $attendanceColor;
					}
				}

				&.excused-event {
					&::before {
						border-color: $excusedColor;
					}
				}

				&.coaching-event {
					&::before {
						border-color: $coachingColor;
					}
				}

				&.discipline-event {
					&::before {
						border-color: $disciplineColor;
					}
				}

				&.recognition-event {
					&::before {
						border-color: $recognitionColor;
					}
				}

				&.review-event {
					&::before {
						border-color: $reviewColor;
					}
				}
			}
		}

		.year-calendar {

			@media print {
				break-inside: avoid;
				break-before: avoid-page;

			}

			.outside-active-window > abbr {
				color: gray;
			}

			// disabling clicking on calendars in print preview, need to not have disabled colors
			button[disabled]:not(.ooutside-active-window) > abbr {
				color: black;
			}
		}
	}

	.printed_date {
		position: absolute;
		right: 50px;
	}

	@media screen {
		box-shadow: 1px 1px 9px 0 rgba(44, 62, 80, 0.5);
		min-height: 1276px;

		.printed_date {
			bottom: 25px;
		}
	}

	@media print {
		height: 100vh;

		.printed_date {
			bottom: 0;
			counter-increment: page;
		}
	}

	.card_header {
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid #CACACA;

		.card_header_left {
			width: 225px;
			margin-right: 98px;

			.employee_fullname,
			.employee_ID,
			.employee_role {
				margin-top: 11px;
			}

			.employee_role {
				margin-bottom: 8px;
			}
		}

		.card_header_right {
			.card_title {
				font-family: Poppins;
				font-style: normal;
				font-weight: 600;
				font-size: 23px;
				line-height: 34px;
				color: #000000;
				margin-bottom: 24px;
			}

			div.row > div {
				margin-bottom: 14px;
			}
		}
	}

	.card_body {
		padding-top: 14px;

		.card_body_title {
			font-family: Poppins;
			font-style: normal;
			font-weight: 600;
			font-size: 23px;
			line-height: 34px;
			color: #000000;
		}

		.card_body_content {
			margin-top: 32px;

			.content_line {
				display: flex;
				justify-content: space-between;
				margin-bottom: 20px;
			}

			.calendar {
				.month-container {
					.day {
						.day-content {
							padding: 1px 6px;
							border-radius: 50%;

							&.recognition-event {
								border: 2px solid $recognitionColor;
							}

							&.review-event {
								border: 2px solid $reviewColor;
							}

							&.coaching-event {
								border: 2px solid $coachingColor;
							}

							&.attendance-event {
								border: 2px solid $attendanceColor;
							}

							&.excused-event {
								border: 2px solid $excusedColor;
							}

							&.discipline-event {
								border: 2px solid $disciplineColor;
							}
						}
					}
				}
			}

		}

	}

	table.table {

		tbody,
		thead {

			td,
			th {
				font-size: 65%;
				padding: .25rem .25rem .25rem .5rem;
			}
		}

		td,
		th {
			&.w-5 {
				width: 5%;
			}

			&.w-10 {
				width: 10%;
			}

			&.w-15 {
				width: 15%;
			}

			&.w-20 {
				width: 20%;
			}

			&.w-25 {
				width: 25%;
			}

			&.w-30 {
				width: 30%;
			}

			&.w-33 {
				width: 33%;
			}

			&.w-40 {
				width: 40%;
			}

			&.w-45 {
				width: 45%;
			}

			&.w-50 {
				width: 50%;
			}

			&.w-55 {
				width: 55%;
			}

			&.w-60 {
				width: 60%;
			}

			&.w-70 {
				width: 70%;
			}

			&.w-80 {
				width: 80%;
			}
		}

		&.table-bordered {
			@media screen {

				thead,
				th,
				td {
					border-color: #2C3F5B;
					border-width: 2px;
					border-style: solid;

					&:first-child {
						padding-left: 10px
					}
				}

				tbody {
					border-bottom: #2C3F5B 2px solid;
				}
			}

			&.connection-table {

				&:not(:last-of-type) {
					margin-bottom: 25px;
				}
			}
		}

		.incident-details {
			line-height: 16px;
		}
	}
}
