// * {
//     margin: 0;
//     padding: 0;
//     box-sizing: border-box;
// }

// .app {
//     max-width: 960px;
//     margin: 0 auto;
// }

// .app p {
//     padding: 10px 0;
// }

.react-lock-screen_ui_wrapper {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;

    .react-lock-screen__ui {
        width: 300px;
        position: fixed;
        border: 4px solid #333;
        background: #fff;
        text-align: center;
        padding: 16px;
        border-radius: 4px;
        top: calc(30% - 152px / 2);
        left: calc(49% - 300px / 2);

        button {
            padding: 5px 15px;
            border: 0;
            text-transform: uppercase;
            background: #333;
            color: #fff;
            margin-top: 15px;
            border-radius: 3px;
            letter-spacing: 1px;
            font-weight: bold;
            cursor: pointer;
        }
    }
}