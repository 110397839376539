.color {
  width: 50px;
  height: 20px;
  border-radius: 4px;
  // background: rgba(215, 159, 55, 1);
}

.popover {
  position: absolute;
  z-index: 2;
}

.cover {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: fixed;
}

.swatch {
  padding: 5px;
  background: #ffffff;
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  box-shadow: 0 0 0 2px rgba(0,0,0,.3);
}
