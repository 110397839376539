#recognition-modal, .recognition-modal {
	.modal-dialog {
		max-width: 802px;
		box-shadow: 0 1px 120px rgba(0, 0, 0, 0.12);
		border-radius: 20px;

		.modal-body {
			.close_button {
				&:hover {
					opacity: 0.7;
				}
			}

			.form-select {
				cursor: pointer;
				background: url('../../../../images/form/form_bg_select.svg') center right 15px no-repeat;
			}
		}
	}
}
