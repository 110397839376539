#notification_view_modal {
  .modal-body {
    max-width: 700px;
    border-radius: 16px;
    font-family: 'Inter';
    font-style: normal;

    .modal_body_header {
      padding: 24px;
      display: flex;
      align-items: center;
      //justify-content: space-between;
      border-bottom: 1px solid #C7C7C7;

      &_left {
        //min-width: 226px;
        padding: 4px 12px;
        background: #F8FAFC;
        border-radius: 8px;

        > img {
          border: 1.5px solid #FFFFFF;
        }

        .header_content {
          margin-left: 5px;
          margin-right: 25px;

          .employee_name {
            font-weight: 600;
            font-size: 10px;
            line-height: 160%;
            color: #64748B;
          }

          .team_name {
            font-weight: 600;
            font-size: 10px;
            line-height: 160%;
            color: rgba(100, 116, 139, 0.5);
          }
        }
      }

      h1 {
        margin-left: -75px;
      }

      button.close_button {
        padding: 8px;

        &:hover {
          opacity: 0.7;
        }
      }
    }

    .modal_body_content {
      padding: 13px 24px 24px 24px;

      .content_top {
        padding-top: 6px;
        padding-bottom: 6px;

        .connection_title {
          font-weight: 700;
          font-size: 22px;
          line-height: 125%;
          color: #0F172A;
        }

        .btn_open {
          padding: 6px 21px;
          border: 1px solid #000000;
          border-radius: 8px;
          font-weight: 700;
          font-size: 12px;
          line-height: 160%;
          color: #000000;
        }
      }

      .details_wrapper {
        .details_header {
          padding: 10px 16px;

          .details_title {
            font-weight: 700;
            font-size: 12px;
            line-height: 160%;
            color: #0F172A;
          }

          .btn_open_details {
            padding: 8px;

            i {
              color: #94A3B8;
            }
          }
        }

        .details_body {
          padding: 16px;
          font-weight: 500;
          font-size: 12px;
          line-height: 160%;

          .item_title {
            margin-bottom: 12px;
            letter-spacing: 1px;
            color: #94A3B8;
          }

          .item_details {
            padding-top: 7px;
            color: #0F172A;
          }
        }
      }

      //.comment_wrapper {
      //    margin-top: 32px;
      //
      //    .comment_header {
      //        .comment_title {
      //            margin-right: 8px;
      //            font-weight: 600;
      //            font-size: 14px;
      //            line-height: 160%;
      //            letter-spacing: 0.2px;
      //            color: #0F172A;
      //        }
      //    }
      //
      //    .comment_body {
      //        margin-top: 7px;
      //        font-weight: 400;
      //        font-size: 12px;
      //        line-height: 160%;
      //        color: #64748B;
      //    }
      //
      //}
    }
  }
}
