.ske-icon {

	&.svg-icon {
		&.xs {
			svg {
				width: 12px;
				height: 12px;

			}
		}

		&.sm {
			svg {
				width: 16px;
				height: 16px;
			}
		}

		&.md {
			svg {
				width: 24px;
				height: 24px;
			}
		}

		&.lg {
			svg {
				width: 32px;
				height: 32px;
			}
		}

		&.xl {
			svg {
				width: 40px;
				height: 40px;
			}
		}
	}
}
