.verify_email_container {
    .link_wrapper {
        display: flex;
        justify-content: flex-end;
        margin-top: -20px;

        button {
            padding: 0 !important;

            i {
                color: #009EF7;
            }

            &:hover {
                i {
                    color: #33b1f9;
                }
            }
        }
    }
}